// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-change-password-tsx": () => import("./../../../src/pages/auth/change-password.tsx" /* webpackChunkName: "component---src-pages-auth-change-password-tsx" */),
  "component---src-pages-auth-complete-js": () => import("./../../../src/pages/auth/complete.js" /* webpackChunkName: "component---src-pages-auth-complete-js" */),
  "component---src-pages-auth-forgot-password-js": () => import("./../../../src/pages/auth/forgot-password.js" /* webpackChunkName: "component---src-pages-auth-forgot-password-js" */),
  "component---src-pages-auth-sign-in-js": () => import("./../../../src/pages/auth/sign-in.js" /* webpackChunkName: "component---src-pages-auth-sign-in-js" */),
  "component---src-pages-auth-sign-up-js": () => import("./../../../src/pages/auth/sign-up.js" /* webpackChunkName: "component---src-pages-auth-sign-up-js" */),
  "component---src-pages-booking-controller-js": () => import("./../../../src/pages/BookingController.js" /* webpackChunkName: "component---src-pages-booking-controller-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-competitions-index-js": () => import("./../../../src/pages/competitions/index.js" /* webpackChunkName: "component---src-pages-competitions-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-first-table-promo-code-js": () => import("./../../../src/pages/first-table-promo-code.js" /* webpackChunkName: "component---src-pages-first-table-promo-code-js" */),
  "component---src-pages-frequently-asked-questions-js": () => import("./../../../src/pages/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-js" */),
  "component---src-pages-gift-vouchers-js": () => import("./../../../src/pages/gift-vouchers.js" /* webpackChunkName: "component---src-pages-gift-vouchers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invite-js": () => import("./../../../src/pages/invite.js" /* webpackChunkName: "component---src-pages-invite-js" */),
  "component---src-pages-magazine-js": () => import("./../../../src/pages/magazine.js" /* webpackChunkName: "component---src-pages-magazine-js" */),
  "component---src-pages-our-story-tsx": () => import("./../../../src/pages/our-story.tsx" /* webpackChunkName: "component---src-pages-our-story-tsx" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-restaurants-js": () => import("./../../../src/pages/restaurants.js" /* webpackChunkName: "component---src-pages-restaurants-js" */),
  "component---src-pages-review-js": () => import("./../../../src/pages/review.js" /* webpackChunkName: "component---src-pages-review-js" */),
  "component---src-pages-sign-up-index-js": () => import("./../../../src/pages/sign-up/index.js" /* webpackChunkName: "component---src-pages-sign-up-index-js" */),
  "component---src-pages-sign-up-promo-js": () => import("./../../../src/pages/sign-up/promo.js" /* webpackChunkName: "component---src-pages-sign-up-promo-js" */),
  "component---src-templates-city-js": () => import("./../../../src/templates/city.js" /* webpackChunkName: "component---src-templates-city-js" */),
  "component---src-templates-fullwidth-js": () => import("./../../../src/templates/fullwidth.js" /* webpackChunkName: "component---src-templates-fullwidth-js" */),
  "component---src-templates-integration-tsx": () => import("./../../../src/templates/integration.tsx" /* webpackChunkName: "component---src-templates-integration-tsx" */),
  "component---src-templates-integrations-faq-tsx": () => import("./../../../src/templates/integrationsFAQ.tsx" /* webpackChunkName: "component---src-templates-integrations-faq-tsx" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-restaurant-js": () => import("./../../../src/templates/restaurant.js" /* webpackChunkName: "component---src-templates-restaurant-js" */)
}

